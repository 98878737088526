/* ========== Bio Section Styling ========== */
.bio-container {
  background-color: black;
  height: 80vh;
  color: white;
  padding: 40px;
}

.bio {
  width: 70%;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  display: block;
  z-index: 1;
}

/* ========== Line Section Styling ========== */
.line {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 20px;
  white-space: nowrap;
}

.line span {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}

.line img {
  height: 30px;
  margin-left: 5px;
}

.larger-icon img {
  height: 45px;
}

/* ========== Text Style Variations ========== */
.invert {
  filter: invert(1);
}

.underline {
  text-decoration: underline;
}

.italics {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

/* ========== Contact Button Styling ========== */
button.contact {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-size: 15px;
  display: block;
  border: 1px solid white;
  margin-left: 5px;
  margin-top: 20px;
  padding: 5px 10px;
  text-align: left;
  color: pink;
  background-color: black;
  cursor: pointer;
}

.contact a {
  color: pink;
  text-decoration: none;
}

/* ========== Responsive Styling ========== */
@media (max-width: 960px) {
  .bio {
    font-size: 20px;
    width: 100%;
  }

  .bio-container {
    height: 100vh;
  }

  .desktop-bio {
    display: none;
  }

  .mobile-bio {
    display: block;
  }

  .line img {
    height: 20px;
  }

  .line {
    margin-bottom: 10px;
  }
}
