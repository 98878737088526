/* ========== Landing Screen Styling ========== */
.LandingScreen {
  width: 100%;
  height: 94vh;
  background-color: #ede8e4;
  color: black;
  position: relative;
}

/* ========== Emoji Styling ========== */
.emoji img {
  display: block;
  height: 50px;
  margin: 0 auto;
}

/* ========== Title Styling ========== */
h1 {
  font-size: 100px;
  font-weight: 600;
  text-align: left;
  margin: 0;
}

.title {
  display: block;
  padding-top: 200px;
  width: 400px;
  margin: 0 auto;
  height: 60%;
}

.title h1 {
  text-align: center;
  font-size: 40px;
}

/* ========== Text and Summary Styling ========== */
.small {
  font-weight: 300;
  font-size: 20px;
}

.text-container h2 {
  font-size: 20px;
}

a.summary {
  font-family: "komet", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  color: black;
}

a.summary:visited,
a.summary:hover {
  color: black;
}

.summary-links {
  padding: 10px;
  color: black;
}

.summary-links h2 {
  color: black;
  font-weight: 400;
  font-size: 12px;
  opacity: 80%;
  margin: 0px;
}

/* ========== Navigation Styling ========== */
.nav-element {
  position: absolute;
  top: 100%;
  right: 100px;
  transform: translateY(-50%);
  width: auto;
  padding: 20px;
}

.nav {
  position: relative;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ========== Image Styling ========== */
.robot-img {
  position: absolute;
  top: 0;
  height: 70px;
}

.arrow-img {
  position: absolute;
  top: 100px;
  height: 150px;
}

/* ========== Details Container Styling ========== */
.details-container {
  margin-left: 30px;
  display: block;
}

/* ========== Responsive Styling ========== */
@media (max-width: 960px) {
  .title {
    padding-top: 120px;
    width: 400px;
    height: 40%;
  }

  .summary-links {
    padding: 5px;
  }

  .summary-links h2 {
    margin: 0 auto;
    padding: 1px;
    font-size: 12px;
  }

  a.summary {
    font-size: 12px;
  }

  .col-sm-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .nav-element {
    top: 104%;
    right: 10%;
  }
}
