/* ========== Footer Section Styling ========== */
.footer {
  font-family: "Quicksand", sans-serif;

  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  background-color: #e4ddec;
  padding: 5px;
  text-align: center;
}

.footer a {
  color: black;
}
