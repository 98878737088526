/* ========== Header Section Styling ========== */
.Header {
  display: flex;
  position: fixed;
  color: black;
  justify-content: space-between;
  z-index: 7;
  width: 100vw;
}

/* ========== Logo Styling ========== */
.logo h2 {
  font-weight: 700;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
}

.logo h2:hover {
  cursor: pointer;
  text-decoration: none;
}

/* ========== Menu Link Styling ========== */
.menu-link {
  padding: 20px 20px 2px 20px;
  color: black;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.menu-link::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: black;
  transition: width 0.3s ease;
}

/* ========== Hover Effects for Links ========== */
.link-text:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.work-link:hover::after {
  width: 60%;
}

.contact-link:hover::after {
  width: 70%;
}

/* ========== Header Items Layout ========== */
.header-item-1 {
  flex: 1;
  margin: 30px;
}

.header-item-2 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px;
}

.header-item-2 span {
  display: flex;
  gap: 20px;
}

/* ========== Responsive Styling for Mobile ========== */
@media (max-width: 600px) {
  .header-item-1 {
    margin: 10px;
  }
  .header-item-2 {
    margin: 10px;
  }
  .header-item-2 span {
    gap: 5px;
  }
}
