@import url("https://fonts.googleapis.com/css2?family=Cactus+Classical+Serif&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Quicksand:wght@300..700&display=swap");

/* Keyframes for marquee scroll animation */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.App {
  margin: 0 auto;
}

main {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
}

.details-container {
  margin-left: 20px;
}

a:hover {
  text-decoration: underline;
}

/* Headings */
h1,
h2,
h4 {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

h1 {
  font-size: 30px;
  margin-bottom: 1px;
}

h2,
h4 {
  font-size: 12px;
  opacity: 80%;
  margin-bottom: 2.5px;
}

h4 {
  padding: 5px;
}

p {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Marquee styles */
.marquee {
  --gap: 1rem;
  display: flex;
  overflow: hidden;
  gap: var(--gap);
  user-select: none;
  position: relative;
  background: #e8daef;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.marquee__content {
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  height: 50px;
  gap: var(--gap);
  min-width: 100%;
  margin: 0;
}

.marquee__content > * {
  flex: 0 0 auto;
  margin: 2px;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  text-align: center;
}

.marquee:hover .marquee__content {
  animation-play-state: paused;
}

.marquee--reverse .marquee__content {
  animation-direction: reverse !important;
}

.marquee__content p {
  color: black;
  font-weight: 500;
}

.marquee__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marquee__content img {
  max-width: 2rem;
  width: 100%;
  object-fit: contain;
}

/* Animation for marquee */
.enable-animation .marquee__content {
  animation: scroll 50s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__content {
    animation-play-state: paused !important;
  }
}
